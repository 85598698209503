import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const CategoriaPage = lazy(() =>
	import("./categoria/CategoriaPage")
);

const MesaPage = lazy(() =>
	import("./mesa/MesaPage")
);

const TerminalPage = lazy(() =>
	import("./terminal/TerminalPage")
);

const ProdutoPage = lazy(() =>
	import("./produto/ProdutoPage")
);

const PedidoPage = lazy(() =>
	import("./pedido/PedidoPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/categoria" component={CategoriaPage} />
				<Route path="/mesa" component={MesaPage} />
				<Route path="/terminal" component={TerminalPage} />
				<Route path="/produto" component={ProdutoPage} />
				<Route path="/pedido" component={PedidoPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
