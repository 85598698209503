import { Constants } from '../../app/utils/Constants';

export default {
	header: {
		self: {},
		items: [
			{
				title: "Manual de Instruções",
				root: true,
				alignment: "left",
				link: Constants.manualLink,
			},
			{
				title: "Download QZ Tray",
				root: true,
				alignment: "left",
				link: Constants.qztrayLink,
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Pedidos",
				root: true,
				icon: "fas fa-shopping-basket",
				page: "pedido",
				bullet: "dot"
			},
			{
				title: "Produtos",
				root: true,
				icon: "fas fa-list",
				page: "produto",
				bullet: "dot"
			},
			{
				title: "Categorias",
				root: true,
				icon: "fas fa-tags",
				page: "categoria",
				bullet: "dot"
			},
			{
				title: "Mesas",
				root: true,
				icon: "fas fa-table",
				page: "mesa",
				bullet: "dot"
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
