let qzTrayCertificate = "-----BEGIN CERTIFICATE-----\nMIIEDTCCAvWgAwIBAgIUUU+SKOT4XcgWM0hZEnlspg/iCAgwDQYJKoZIhvcNAQELBQAwgZQxCzAJBgNVBAYTAkJSMQ8wDQYDVQQIDAZQYXJhbmExFTATBgNVBAcMDFBvbnRhIEdyb3NzYTESMBAGA1UECgwJTGFvbiBMYWJzMQwwCgYDVQQLDANEZXYxEjAQBgNVBAMMCUxhb24gTGFiczEnMCUGCSqGSIb3DQEJARYYcmFmYWVsLmFsdGhhdXNAZ21haWwuY29tMCAXDTIxMDcwNTE3NTMxN1oYDzIwNTIxMjI4MTc1MzE3WjCBlDELMAkGA1UEBhMCQlIxDzANBgNVBAgMBlBhcmFuYTEVMBMGA1UEBwwMUG9udGEgR3Jvc3NhMRIwEAYDVQQKDAlMYW9uIExhYnMxDDAKBgNVBAsMA0RldjESMBAGA1UEAwwJTGFvbiBMYWJzMScwJQYJKoZIhvcNAQkBFhhyYWZhZWwuYWx0aGF1c0BnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDhTzQ8r8BO/pxqB4w6Qcg3RPFanyUtKKRU6b0Z4o9Qn+GkKOY+60DBB/SNiYO7y0xG39IiSFddrbD1QQtVvclf2jQJNkyC2NlXX8A55/t/Nr6kGlYXUMkXWbQNm/49gO2Mm3qXG7hKE57TyUyGATJZ0h7LQYyDNxommO8jjeAQqEldwY3CBmKM+p3UmQ4G+PAC2igGRd84A4lpR7NbjSJj7WcP+xrp0eDdzR4vGsHTXwW3sW1PRVNC7Jb3FerJltV0jIVKiG9eYZu0Ddh5EpSBAZz8OuwIGU9OapP8+pFc0Zpi+mSwr2EyUcT/PHHu/WZhegr3hn4iPMY7YqmHtgAnAgMBAAGjUzBRMB0GA1UdDgQWBBTOj9XzfzFSUxRH1837plfjTR27YzAfBgNVHSMEGDAWgBTOj9XzfzFSUxRH1837plfjTR27YzAPBgNVHRMBAf8EBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQCDu7GhdRAjuw1mWO9KrBovKg6u3ZjJiGX5a5o08RTGd2L9DDSSzO0l/HuPmNWmNBeXUM36sIoMw/3OGt/dBUQjRNjvhtfdMqIw4nUs38/YdU4pBHxC9RLUtahWYRC6m5sPe5zYsYAcS9YdvHor6IAguKwdAep/1RRUJJFBSQb4bMkuo9i2lZM4mP837ZVlHGJ6nBXS9AFXFAPGEAWj0Wk5G9sEWbVl8vcXjnjLNef178Vj4iim1SgUCIgU8hmnI2kbpp2wVIRbFhBVW7frSlFxT/+wKynNMrWZDmqY2v7iie6XoFROjEZj+cb7T8ZSBjxQmB2oOcB38BNfAObwto8S\n-----END CERTIFICATE-----";
let qzTrayPrivateKey = "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDhTzQ8r8BO/pxqB4w6Qcg3RPFanyUtKKRU6b0Z4o9Qn+GkKOY+60DBB/SNiYO7y0xG39IiSFddrbD1QQtVvclf2jQJNkyC2NlXX8A55/t/Nr6kGlYXUMkXWbQNm/49gO2Mm3qXG7hKE57TyUyGATJZ0h7LQYyDNxommO8jjeAQqEldwY3CBmKM+p3UmQ4G+PAC2igGRd84A4lpR7NbjSJj7WcP+xrp0eDdzR4vGsHTXwW3sW1PRVNC7Jb3FerJltV0jIVKiG9eYZu0Ddh5EpSBAZz8OuwIGU9OapP8+pFc0Zpi+mSwr2EyUcT/PHHu/WZhegr3hn4iPMY7YqmHtgAnAgMBAAECggEBALU3BhW1aeBfSqDBF1kX5ZgGfHTdwYHzyVB+etjh71DydwKY3q9GeduHsXtRWkHl/qCWjqWCN7qh480ulpZMwjW1A9tQ7/LhxNVQrwQkAJTODEFi6VFxaXu9a2Lyz2baVrWbq9rzsaLq6vj+U+0H815o8NmcDA7Rd9gJc3XOO6RIo/ly4KeDbhzCY9rUIKIqwihsuaOw5Ybp8F8yPTFaLGSlPZv9UhP7CfqqwU7pHePpC/kraZJRH+sY36I4Io6LRp7Ii8jONGKzMsBDqy20ZRrn6aqWRV3ThOaSGkZsaS1c0HkTWoGr5fHQChEpC2JdzbItqJMHtgy1xH2G4lngRCkCgYEA8hXpJ0fnxFjIkZ2nmoc6Lj2GMXBr4+wnlwFAmB5HpbCEP4VMcgwluJbjNYHCfLUNAQwchzdYIAruWaaGzL6LuXAgga/wVFqUet94af3+63QZtN0+g5Pe+3HEE+Ijn1TBvtBYAEww8NIb+draoROQvJieoxcGcc4oPVZ5fghzM0MCgYEA7kJyBqmoAprc83RJsdP97lC4SYQPFBwnmQ65pT0g+dQ4J332hgIT2PMWvu0K96KNo6l7T20frxyAIerxrMSHuxenTnGH84kSA1khRH43XhYTP+6dI8j6MRP3xc9iD9aGIM9eLCqwFfItblDKe/Nn4krENOcgzvZcpoX3R3VIR00CgYEAoTkSY8Sxh8QRH1k9i95PUwhKXJagY9ScsSZ2XFieIqyiBqPBu5p3bK2gd7wJSIBTPsVQIoUrPXvKLQ6Ia8biywbvvy7rAWKpzAMT8JLluO1DSX6m6X4LXK3xhcRvMOeDeI7MbLZRSbDETOwB+pBLLi7+R2NIOXawudjjZJpADn0CgYBPjyh8SRKtLAEz2lTxaJSWi6W+BrbyRCSE24HqR2tHgTHvHgS/4tm1zBaAUq8i2cjqKaHfd9ToqF+w878XrdiVBOT9MLPxAohdTdnqASuD42MyKf+W/3lsAfrutr1MBJptQlFz9h0udtvsxlWW5kR+L/EsqTEvatytTnawCd3E0QKBgQC0dz5jH8WxoZIZDAn/R4BNEwEj6nN0Mw+283idzS13wwGWLbtbA4h9MqK+KvGqsy5i5HyrRxzNUyST5Q9vzG3seUx3QyBjawTDoo4sK5IVsnuxHYNkXa69r2He5NJs7VjNj0Mb6bBnPaDX2+2+3hSC9sbvJIPzYg9DqWbVKUO7Ww==\n-----END PRIVATE KEY-----";

let url = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
} else {
	url = "https://api.purehops.laonlabs.com";
}

export const Constants = {
	url,
	baseUrl: url + "/api/admin",
	manualLink: "https://docs.google.com/document/d/1Qr_Pfkk1sTVwlr3F-zs7EyIo0dykKi6mxdADx9BwUfc/edit?usp=sharing",
	qztrayLink: "https://laon-public.s3.sa-east-1.amazonaws.com/qz-tray-2.1.3-laon-labs-signed.exe",
	qzTrayCertificate,
	qzTrayPrivateKey
};
